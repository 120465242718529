export default {
    prefix: 'fab',
    iconName: 'kobo',
    icon: [
        500, 271,
        [],
        null,
        'M428.67 145.55c18.65 0 21.48-19.15 21.48-40s-2.83-39.94-21.48-39.94-21.5 19.13-21.5 39.94 2.83 40 21.5 40m-49.05-40c0-36.44 17-58.16 49.05-58.16s49 21.72 49 58.16-17.05 58.17-49 58.17-49.06-21.72-49.06-58.13m-60 40c16.61 0 19.4-21.73 19.4-40C339 85.51 336.86 64 319.57 64 303.93 64 299 86.91 299 105.59c0 27.79 4.2 40 20.54 40M299 63.31h.45c3.51-5.6 11.68-15.88 28.74-15.88 26.64 0 38.3 22.66 38.3 58.16 0 31.06-10.74 58.17-47 58.17-29.18 0-46.47-16.59-46.47-49.76V0H299v63.31zm-89.1 82.23c18.68 0 21.48-19.15 21.48-40s-2.8-39.94-21.48-39.94-21.49 19.13-21.49 39.94 2.77 40 21.49 40m-49.05-40c0-36.44 17.05-58.16 49.05-58.16s49 21.72 49 58.16-17 58.17-49 58.17-49.02-21.71-49.02-58.12M66.54 0h25.91v92.53l36-42.53h31.08l-41.16 48.12 44.84 63.08h-31.07l-39.22-56.07h-.47v56.07H66.54V0zM385.47 205.65q-46.59 0-63.64 2.37a85.49 85.49 0 0 0-31.17 10.51q-16.61 9.65-21.36 26.22H266q-3.15-14.1-19-24.24-11.86-7.67-28.67-11c-11.24-2.22-25.52-3.31-42.95-3.31l-41.13-.27H78.44c-11.24 0-21.16 0-29.91-.06L0 233.6a347.6 347.6 0 0 0 41.61 2.22l43.49-.24 65.67-.75 47.05-.72q31.26 0 46.29 8.42t19 28.21h8.7a46.32 46.32 0 0 1 11.85-22.28q7.48-7.69 19.57-10.91c8-2.16 19.41-3.19 34.16-3.19l40.29.24 29.59.35v-29.19l-21.78-.11zM424.52 234.88h29.12v-29.13h-29.12zM470.17 234.88h29.13v-29.13h-29.13z',
    ],
};
