export default {
    prefix: 'fab',
    iconName: 'bb',
    icon: [
        228, 228,
        [],
        null,
        'M18.91,191.29V34.71H72.2q24.15,0,37.77,9.62t13.61,26.86a33.73,33.73,0,0,1-8,22.18q-8,9.63-21.48,13.78v.32q16.59,2.13,26.38,12.34t9.78,25.32q0,21.06-15.1,33.61T74.75,191.29ZM49.22,58V99.06H65.71q12.24,0,19.15-5.79t6.92-16.33q0-18.93-27-18.94Zm0,64.47V168H70.07q13.41,0,20.85-6.17t7.45-17.13q0-22.23-29.36-22.23Z M135.38,191.29V34.71h53.3q24.15,0,37.76,9.62t13.62,26.86a33.73,33.73,0,0,1-8,22.18q-8,9.63-21.49,13.78v.32q16.6,2.13,26.38,12.34t9.79,25.32q0,21.06-15.11,33.61t-40.42,12.55ZM165.7,58V99.06h16.49q12.23,0,19.15-5.79t6.91-16.33q0-18.93-27-18.94Zm0,64.47V168h20.85q13.41,0,20.85-6.17t7.45-17.13q0-22.23-29.36-22.23Z',
    ],
};
